<template>
  <v-app>
    <router-view/>
    <v-snackbar
      v-model="globalSnackbar"
      :color="globalSnackbarColor"
      :timeout="5000"
      :top="true"
      class="text-center justify-center"
    >
      <div class="text-subtitle-2 text-center flex-grow-1">{{ globalSnackbarText }}</div>
    </v-snackbar>
  </v-app>
</template>

<script>
  import localize from '@/filters/localize';

  export default {
    watch: {
      $route (to) {
        document.title = localize(to.meta.title) + ' | Fraktus.se' || 'Fraktus'
      }
    },
    computed: {
      globalSnackbar: {
        get() {
          return this.$store.getters.globalSnackbar;
        },
        set(newValue) {
          this.$store.commit('changeGlobalSnackbar', newValue);
        },
      },
      globalSnackbarColor() {
        return this.$store.getters.globalSnackbarColor;
      },
      globalSnackbarText() {
        return this.$store.getters.globalSnackbarText;
      },
    },
  };
</script>

<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
    background-color: #fafafa;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .v-text-field__details {
    padding: 0 !important;

    .v-messages__message {
      line-height: 1.2 !important;
    }
  }

  .google-autocomplete {
    width: 100%;
    line-height: 20px;
    padding: 10px 40px;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #d6d6d6 !important;
    border-radius: 5px !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87) !important;
    }

    &:focus {
      border: 1px solid #fc8101 !important;
      outline: 1px solid #fc8101 !important;
    }
  }

  .pac-container {
    width: 500px !important;
    border: 1px solid #ccc;
    box-shadow: none;
  }

  .pac-item {
    padding: 5px 10px;
  }

  .pac-logo:after {
    height: 42px;
    background-position: center right 0.9375rem;
    background-color: #fafafa;
    border-top: 1px solid #e3e3e3;
  }

  .google-search-icon {
    position:absolute;
    width:40px;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  }

  .google-close-icon {
    position:absolute;
    right: 0;
    width:40px;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
</style>
