import axios from 'axios';

let apiUrl = process.env.VUE_APP_API_URL;

export default {
  getUser: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/user`,
    });
  },
  updateUser: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/user/update`,
      data: payload
    });
  },
  updateUserSettings: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/user/setting`,
      data: payload
    });
  },
  login: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/login`,
      data: payload
    });
  },
  logout: () => {
    return axios({
      method: 'post',
      url: `${apiUrl}/logout`,
    });
  },
  sendResetLink: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/send-reset-link`,
      data: payload
    });
  },
  resetPassword: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/reset-password`,
      data: payload
    });
  },
  wpLogin: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/wp-login`,
      data: payload
    });
  },
  getWpUserName: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/wp-customer/${id}/name`,
    });
  },
  checkToken: () => {
    return axios({
      method: 'post',
      url: `${apiUrl}/check-token`
    });
  },
  getSingleCompanyUser: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/user/${id}`,
    });
  },
};
