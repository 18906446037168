export const TRANSPORT_TYPES = {
  SEA: 'sea',
  ROAD: 'road',
  AIR: 'air',
  RAIL: 'rail',
}
export const TRANSPORT_ICONS = {
  [TRANSPORT_TYPES.SEA]: 'mdi-ferry',
  [TRANSPORT_TYPES.ROAD]: 'mdi-truck',
  [TRANSPORT_TYPES.AIR]: 'mdi-airplane',
  [TRANSPORT_TYPES.RAIL]: 'mdi-train',
}
