import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {ROLES} from '@/enums/roles';
import methods from '@/helpers/methods';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      let confirmationData = methods.getCookie('confirmation') ? JSON.parse(methods.getCookie('confirmation')) : null;
      let userData = JSON.parse(localStorage.getItem('userData'));
      if (userData?.role === ROLES.COMPANY) {
        if (confirmationData) {
          return {name: 'booking-summary', query: {request_id: confirmationData.request_id, proposal_id: confirmationData.proposal_id, mode: confirmationData.mode}};
        }
        return {name: 'company-requests'};
      }
      if (userData?.role === ROLES.CLIENT) return {name: 'customer-requests'};
      if (userData?.role === ROLES.ADMIN) return {name: 'admin-requests'};
      return {name: 'login'};
    },
    component: () => import('../views/Home'),
    children: [
      {
        path: 'customer-requests',
        name: 'customer-requests',
        meta: {
          roleRestriction: [ROLES.CLIENT],
          title: 'myQuotes'
        },
        component: () => import('../views/CustomerRequests'),
      },
      {
        path: 'new-request',
        name: 'new-request',
        meta: {
          roleRestriction: [ROLES.CLIENT],
          title: 'newRequest'
        },
        component: () => import('../views/NewRequest'),
      },
      {
        path: 'request-details',
        name: 'request-details',
        meta: {
          roleRestriction: [ROLES.CLIENT, ROLES.ADMIN],
          title: 'allQuotations'
        },
        component: () => import('../views/RequestDetails'),
      },
      {
        path: 'booking',
        name: 'booking',
        meta: {
          roleRestriction: [ROLES.CLIENT, ROLES.ADMIN],
          title: 'createBooking'
        },
        component: () => import('../views/Booking'),
      },
      {
        path: '/account',
        name: 'account',
        meta: {
          roleRestriction: [ROLES.CLIENT],
          title: 'myAccount'
        },
        component: () => import('../views/Account'),
      },
      {
        path: '/company-account',
        name: 'company-account',
        meta: {
          roleRestriction: [ROLES.COMPANY],
          title: 'myAccount'
        },
        component: () => import('../views/CompanyAccount'),
      },
      {
        path: '/company-profile',
        name: 'company-profile',
        meta: {
          roleRestriction: [ROLES.COMPANY],
          title: 'companyProfile'
        },
        component: () => import('../views/CompanyProfile'),
      },
      {
        path: 'company-requests',
        name: 'company-requests',
        meta: {
          roleRestriction: [ROLES.COMPANY],
          title: 'myRequests'
        },
        component: () => import('../views/CompanyRequests'),
      },
      {
        path: 'company-request-details',
        name: 'company-request-details',
        meta: {
          roleRestriction: [ROLES.COMPANY],
          title: 'requestDetails'
        },
        component: () => import('../views/CompanyRequestDetails'),
      },
      {
        path: 'booking-summary',
        name: 'booking-summary',
        meta: {
          roleRestriction: [ROLES.COMPANY, ROLES.CLIENT, ROLES.ADMIN],
          title: 'bookingSummary'
        },
        component: () => import('../views/BookingSummary'),
      },
      {
        path: 'create-quote',
        name: 'create-quote',
        meta: {
          roleRestriction: [ROLES.COMPANY],
          title: 'createQuote'
        },
        component: () => import('../views/CreateQuote.vue'),
      },
      {
        path: 'admin-requests',
        name: 'admin-requests',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'requests'
        },
        component: () => import('../views/AdminRequests'),
      },
      {
        path: 'admin-request-details',
        name: 'admin-request-details',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'requestDetails'
        },
        component: () => import('../views/AdminRequestDetails'),
      },
      {
        path: 'admin-companies',
        name: 'admin-companies',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'companies'
        },
        component: () => import('../views/AdminCompanies'),
      },
      {
        path: 'price-settings',
        name: 'price-settings',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'marginSettings'
        },
        component: () => import('../views/PriceSettings'),
      },
      {
        path: 'create-company',
        name: 'create-company',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'createCompany'
        },
        component: () => import('../views/CreateCompany'),
      },
      {
        path: 'create-user',
        name: 'create-user',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'createUser'
        },
        component: () => import('../views/CreateUser'),
      },
      {
        path: 'create-third-level',
        name: 'create-third-level',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'createCustomerMargin'
        },
        component: () => import('../views/CreateThirdLevel'),
      },
      {
        path: 'users-relevancy',
        name: 'users-relevancy',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'usersRelevancy'
        },
        component: () => import('../views/UsersRelevancy.vue'),
      },
      {
        path: 'user-relevancy',
        name: 'user-relevancy',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'userRelevancy'
        },
        component: () => import('../views/UserRelevancy.vue'),
      },
      {
        path: 'clients',
        name: 'clients',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'clients'
        },
        component: () => import('../views/Clients.vue'),
      },
      {
        path: 'global-settings',
        name: 'global-settings',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'globalSettings'
        },
        component: () => import('../views/AdminGlobalSettings.vue'),
      },
      {
        path: 'general-text-data',
        name: 'general-text-data',
        meta: {
          roleRestriction: [ROLES.ADMIN],
          title: 'generalTextData'
        },
        component: () => import('../views/AdminGeneralTextData.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      headerPlaceholder: true,
      title: 'login'
    },
    component: () => import('../views/Login'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      headerPlaceholder: true,
      title: 'resetPassword'
    },
    component: () => import('../views/ResetPassword'),
  },
  {
    path: '/external-booking-summary',
    name: 'external-booking-summary',
    meta: {
      headerPlaceholder: true,
      title: 'bookingSummary'
    },
    component: () => import('../views/ExternalBookingSummary'),
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: 'login'
    },
    component: () => import('../views/Auth'),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      headerPlaceholder: true,
      title: '404_title'
    },
    component: () => import('../views/NotFound'),
  },
  {
    path: '*',
    redirect: '404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let checkLocale = localStorage.getItem('locale');
  checkLocale && store.commit('changeLocale', checkLocale);
  let userData = JSON.parse(localStorage.getItem('userData'));
  userData && store.commit('changeUserData', userData);
  let isRoleRestriction = to.meta['roleRestriction'];
  let isClientLink = to.query['is_client'] === 'true';
  let isConfirmation = to.query['confirmation'] === 'true';
  if (isConfirmation) {
    document.cookie = `confirmation=${JSON.stringify(to.query)}; max-age=30;`;
  }
  if (!userData) {
    if (isClientLink) return methods.goToFraktus();
    if (isRoleRestriction) return next({name: 'login'});
    return next();
  }
  if (isRoleRestriction) {
    if (isClientLink && !store.getters.isCustomer) return methods.goToFraktus();
    if (!to.meta['roleRestriction'].includes(userData.role)) return next({name: '404'});
    return next();
  }
  next();
});

export default router;
