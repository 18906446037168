import store from '@/store'
import en from '@/locales/en.json'
import se from '@/locales/se.json'

let locales = {
  'en' : en,
  'se' : se
}

export default function localize(key) {
  let locale = store.getters.locale;
  return locales[locale][key] || `*ERROR* *${key}* not found`;
}
