import axios from 'axios';

let apiUrl = process.env.VUE_APP_API_URL;

export default {
  getRequestOffers: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/customer/request/offer/all`,
      params: payload,
    });
  },
  rejectOffer: (id) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/customer/request/offer/reject/${id}`,
    });
  },
  bookOffer: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/customer/request/offer/book`,
      data: payload,
    });
  },
  getBookingSummaryCustomer: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/customer/request/offer/booking-summary/${id}`,
    });
  },
  viewedOffer: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/customer/request/offer/viewed`,
      data: payload,
    });
  },
  getSingleCustomerRequest: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/customer/request/${id}`,
    });
  },
  getCustomerRequests: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/customer/request/all`,
      params: payload,
    });
  },
  saveCustomerRequest: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/customer/request`,
      data: payload,
    });
  },
  updateCustomerRequest: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/customer/request/update`,
      data: payload,
    });
  },
  sendCustomerRequest: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/customer/request/send`,
      data: payload,
    });
  },
  deleteCustomerRequest: (id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/customer/request/${id}`,
    });
  },
  cancelCustomerRequest: (id) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/customer/request/cancel/${id}`,
    });
  },
};
