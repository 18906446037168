import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "./scss/main.scss";
import localize from '@/filters/localize';
import apiLocalize from '@/filters/apiLocalize';
import formatDate from '@/filters/formatDate';
import columnizeDates from '@/filters/columnizeDates';

Vue.config.productionTip = false

Vue.filter('localize', localize);
Vue.filter('apiLocalize', apiLocalize);
Vue.filter('formatDate', formatDate);
Vue.filter('columnizeDates', columnizeDates);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
