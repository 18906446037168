export const GOOD_TYPES = {
  FTL: 'ftl',
  FTL_VALUE: 'FTL',
  PALLET: 'pallet',
  PALLET_VALUE: 'Pallet',
  PACKAGE: 'package',
  PACKAGE_VALUE: 'Package',
  ['40_HC']: '40hc',
  ['40_HC_VALUE']: '40\' HC',
  ['40']: '40',
  ['40_VALUE']: '40\'',
  ['20']: '20',
  ['20_VALUE']: '20\'',
};
