export const PRODUCT_TYPES = {
  BOOKS: 'books',
  BUILDING_MATERIALS: 'building_materials',
  CLOTHING: 'clothing',
  EATABLES: 'eatables',
  ELECTRONIC_GOODS: 'electronic_goods',
  FURNITURE: 'furniture',
  HOUSEHOLD_SUPPLIES: 'household_supplies',
  INDUSTRIAL_GOODS: 'industrial_goods',
  TOOLS: 'tools',
  WHITE_GOODS: 'white_goods',
  OTHER: 'other',
}
