export const SPECIAL_REQUIREMENTS_TYPES = {
  STANDARD: 'standard',
  DANGEROUS_GOODS: 'dangerous_goods',
  TEMPERED_GOODS: 'tempered_goods',
}

export const SPECIAL_REQUIREMENTS_ICONS = {
  [SPECIAL_REQUIREMENTS_TYPES.STANDARD]: 'mdi-package-variant-closed-check',
  [SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]: 'mdi-fire',
  [SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]: 'mdi-snowflake-thermometer',
}

export const SPECIAL_REQUIREMENTS_COLORS = {
  [SPECIAL_REQUIREMENTS_TYPES.STANDARD]: 'teal lighten-3',
  [SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]: 'deep-orange lighten-1',
  [SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]: 'blue lighten-2',
}
