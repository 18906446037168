export const DELIVERY_TERMS = {
  EXW: 'EXW',
  FCA: 'FCA',
  FAS: 'FAS',
  FOB: 'FOB',
  CFR: 'CFR',
  CIF: 'CIF',
  CPT: 'CPT',
  CIP: 'CIP',
  DAP: 'DAP',
  DPU: 'DPU',
  DDP: 'DDP'
}
