import axios from 'axios';

let apiUrl = process.env.VUE_APP_API_URL;

export default {
  getCompanyProfile: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/profile/${id}`,
    });
  },
  updateCompanyProfile: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/profile/update`,
      data: payload,
    });
  },
  getCompanyRequests: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/request/all`,
      params: payload,
    });
  },
  getSingleCompanyRequest: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/request`,
      params: payload,
    });
  },
  getCompanyProposals: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/proposal/all`,
      params: payload,
    });
  },
  getSingleCompanyProposal: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/proposal/${id}`,
    });
  },
  getBookingSummaryCompany: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/proposal/booking-summary`,
      params: payload,
    });
  },
  bookingConfirmation: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/proposal/booking-confirmation`,
      data: payload,
    });
  },
  getExternalBookingSummaryCompany: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/proposal/external-booking-summary`,
      data: payload,
    });
  },
  externalBookingConfirmation: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/proposal/external-booking-confirmation`,
      data: payload,
    });
  },
  saveCompanyProposal: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/company/proposal`,
      data: payload,
    });
  },
  sendCompanyProposal: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/company/proposal/send`,
      data: payload,
    });
  },
  updateCompanyProposal: (payload) => {
    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${apiUrl}/company/proposal/update`,
      data: payload,
    });
  },
  deleteCompanyProposal: (id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/company/proposal/${id}`,
    });
  },
  confirmCompanyProposal: (proposal_id) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/proposal/${proposal_id}/confirm/current-price`,
    });
  },
  declineRequest: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/company/request/decline`,
      data: payload,
    });
  },
  getCompanyRelevancy: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/company/profile/relevancy/${id}`,
    });
  },
};
