import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import styleVariables from '@/scss/base/_variables.scss'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: styleVariables['orange'],
        success: styleVariables['atlantis'],
        warning: styleVariables['fuel-yellow'],
        'silver-chalice': styleVariables['silver-chalice'],
        'alabaster': styleVariables['alabaster'],
        'serenade': styleVariables['serenade']
      }
    }
  }
});
