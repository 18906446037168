export const STATUSES = {
  NEW: 'new',
  NEED_RESPONSE: 'need_response',
  PENDING: 'pending',
  CLOSED: 'closed',
  DRAFT: 'draft',
  BOOKED: 'booked',
  NOT_BOOKED: 'not_booked',
  REJECTED: 'rejected',
  DECLINED: 'declined',
  VIEWED: 'viewed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  DELETED: 'deleted',
  ACTION_REQUIRED: 'action_required',
};
export const STATUS_ICONS = {
  [STATUSES.NEW]: 'mdi-new-box',
  [STATUSES.NEED_RESPONSE]: 'mdi-bell-ring-outline',
  [STATUSES.PENDING]: 'mdi-timer-pause-outline',
  [STATUSES.CLOSED]: 'mdi-lock',
  [STATUSES.DRAFT]: 'mdi-file-document-edit-outline',
  [STATUSES.BOOKED]: 'mdi-checkbox-marked-circle-outline',
  [STATUSES.NOT_BOOKED]: 'mdi-cancel',
  [STATUSES.REJECTED]: 'mdi-undo-variant',
  [STATUSES.DECLINED]: 'mdi-archive',
  [STATUSES.VIEWED]: 'mdi-eye',
  [STATUSES.CANCELLED]: 'mdi-cancel',
  [STATUSES.EXPIRED]: 'mdi-clock',
  [STATUSES.DELETED]: 'mdi-delete',
  [STATUSES.ACTION_REQUIRED]: 'mdi-alert-circle-outline',
};
export const STATUS_COLORS = {
  [STATUSES.NEW]: 'success',
  [STATUSES.NEED_RESPONSE]: 'warning',
  [STATUSES.PENDING]: 'warning',
  [STATUSES.CLOSED]: 'silver-chalice',
  [STATUSES.DRAFT]: 'error',
  [STATUSES.BOOKED]: 'success',
  [STATUSES.NOT_BOOKED]: 'silver-chalice',
  [STATUSES.REJECTED]: 'error',
  [STATUSES.DECLINED]: 'silver-chalice',
  [STATUSES.VIEWED]: 'silver-chalice',
  [STATUSES.CANCELLED]: 'error',
  [STATUSES.EXPIRED]: 'silver-chalice',
  [STATUSES.DELETED]: 'silver-chalice',
  [STATUSES.ACTION_REQUIRED]: 'warning',
};
