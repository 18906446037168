import store from '@/store';
import router from '@/router';
import localize from '@/filters/localize';
import api from '@/api/api';

let fraktusUrl = process.env.VUE_APP_FRAKTUS_URL;

export default {
  backToLogin() {
    window.location.pathname = '/login';
  },
  goToFraktus() {
    window.location.href = fraktusUrl;
  },
  clearUserData() {
    store.commit('changeUserData', null);
    localStorage.removeItem('userData');
  },
  saveUserData(userData) {
    store.commit('changeUserData', userData);
    localStorage.setItem('userData', JSON.stringify(userData));
  },
  afterLogin(locale) {
    api.updateUserSettings({locale: locale});
    router.push({name: 'home'});
  },
  logOut() {
    let isCustomer = store.getters.isCustomer;
    this.clearUserData();
    isCustomer ? this.goToFraktus() : router.push({name: 'login'});
  },
  saveRequestId(id) {
    localStorage.setItem('open_request', id);
  },
  getErrors(response) {
    if (response.status === 500) {
      return '!!! SERVER ERROR !!!';
    } else {
      if (!response.data || (response.data && !response.data.errors && !response.data.message)) return '!! SOMETHING WENT WRONG !!';
      if (response.data.errors) {
        let errors = Object.keys(response.data.errors).map(key => [key, response.data.errors[key]]);
        return errors[0][1].join(' ');
      }
      if (response.data.message) {
        return response.data.message;
      }
    }
  },
  totalWeight(goods) {
    let totalWeight = goods.reduce(
      (acc, item) => acc + Number(item.weight),
      0,
    );
    return isNaN(totalWeight) ? 0 : totalWeight;
  },
  totalVolume(goods) {
    let totalVolume = parseFloat(goods.reduce((acc, item) => acc + Number(item.volume), 0).toFixed(4));
    return isNaN(totalVolume) ? 0 : totalVolume;
  },
  showGlobalSnackbar(color, text) {
    store.commit('changeGlobalSnackbarColor', color);
    store.commit('changeGlobalSnackbarText', text);
    store.commit('changeGlobalSnackbar', true);
  },
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  },
  timeIntervals() {
    let arr = [], i, j;
    for (i = 0; i < 24; i++) {
      for (j = 0; j < 2; j++) {
        arr.push(('0' + i).slice(-2) + ':' + (j === 0 ? '00' : '30'));
      }
    }
    return arr;
  },
  intervals() {
    let localIntervals = [...this.timeIntervals().map(item => {
      return {key: item, value: item};
    })];
    localIntervals.unshift({key: '', value: localize('anyTime')});
    return localIntervals;
  },
};
